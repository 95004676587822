import axios from '~/util/axios'
// eslint-disable-next-line no-restricted-imports
import moment from 'moment'

export default {

  name (name) {
    var nameRegEx = /^[\p{L}0-9\-‘’' .&]+$/u
    return nameRegEx.test(name)
  },

  address (address) {
    const url = `/api/v1/address_validation?&address=${address.address}&city=${address.city}&state=${address.state}&zip=${address.zip}`
    return axios.get(url)
  },

  email (email) {
    var emailRegEx = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/
    return emailRegEx.test(email.toLowerCase())
  },

  phone (phone) {
    var phoneRegEx = /^(1-)?(\(?\d{3}\)?-?\d{3}-?\d{4}|\(?\d{3}\)?.?\d{3}.?\d{4}|\d{10})$/
    return phoneRegEx.test(phone)
  },

  ssn (ssnValue) {
    var ssnRegEx = /^(\d{3}-?\d{2}-?\d{4}|XXX-XX-XXXX|\d{9})$/
    return ssnRegEx.test(ssnValue)
  },

  zip (zipValue) {
    var zipRegEx = /(^\d{5}$)|(^\d{5}-\d{4}$)/
    return zipRegEx.test(zipValue)
  },

  website (url) {
    var websiteRegEx = /([A-Za-z]{3,9}):\/\/([-;:&=+$,\w]+@{1})?([-A-Za-z0-9.]+)+:?(\d+)?((\/[-+~%/.\w]+)?\??([-+=&;%@.\w]+)?#?([\w]+)?)?/
    return websiteRegEx.test(url)
  },

  url (url) {
    var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', 'i') // fragment locater
    return pattern.test(url)
  },

  password (pw) {
    if (pw === null || pw === '' || pw.length < 9 || !/(?=.*[a-z])/.test(pw) || !/(?=.*[A-Z])/.test(pw) || !/(?=.*[0-9])/.test(pw)) {
      return false
    } else {
      return true
    }
  },

  date (date) {
    return moment(date, 'MM/DD/YYYY').isValid() && moment(date).format('MM/DD/YYYY') === date
  }
}
