export default {
  data () {
    return {
      validImage: true
    }
  },
  computed: {
    sn_snDefaultAvatarMixins_initials () {
      if (this.useFullInitials && this.servicerName.split(' ').length > 1) {
        // a bit complicated for what it's doing but its to avoid issues with middle names etc
        const names = this.servicerName.split(' ')
        const firstName = names.shift()
        const lastName = names.pop()

        return `${firstName.charAt(0).toUpperCase()}${lastName.charAt(0).toUpperCase()}`
      } else {
        return this.servicerName.charAt(0).toUpperCase()
      }
    },
    sn_snDefaultAvatarMixins_showImage () {
      if (this.validImage && this.imageUrl && this.imageUrl !== '' && this.imageUrl !== 'https://simplenexus.com/images/profile_sihlouette.png') return true
      return false
    }
  }
}
