<template>
  <v-avatar
    v-if="sn_snDefaultAvatarMixins_showImage"
    :size="squareSize"
    :tile="tile"
  >
    <img
      :src="imageUrl"
      :alt="servicerName"
      @error="validImage = false"
    >
  </v-avatar>
  <v-avatar
    v-else-if="showIcon"
    :color="avatarColor"
    :size="squareSize"
    :tile="tile"
    dark
  >
    <v-icon
      dark
      :x-large="xLarge"
      :large="large"
      :medium="medium"
      :small="small"
    >
      {{ icon }}
    </v-icon>
  </v-avatar>
  <v-avatar
    v-else-if="showAccountIcon"
    :size="squareSize"
    :tile="tile"
  >
    <v-icon
      large
      data-cy="account-avatar"
    >
      account_circle
    </v-icon>
  </v-avatar>
  <v-avatar
    v-else
    :size="squareSize"
    :color="avatarColor"
    :tile="tile"
    data-cy="account-avatar"
  >
    <span
      :style="`font-size: ${initialSize}px;`"
      class="white--text"
    >
      <span :style="initialStyle">{{ sn_snDefaultAvatarMixins_initials }}</span>
    </span>
  </v-avatar>
</template>

<script>
import snDefaultAvatarMixin from './mixins/snDefaultAvatarMixin'
export default {
  name: 'DefaultAvatar',
  mixins: [snDefaultAvatarMixin],
  props: {
    avatarColor: {
      type: String,
      default: 'primary',
      required: false
    },
    icon: {
      type: String,
      default: '',
      required: false
    },
    initialSize: {
      type: String,
      default: '28',
      required: false
    },
    imageUrl: {
      type: String,
      default: '',
      required: false
    },
    large: {
      type: Boolean,
      default: true
    },
    medium: {
      type: Boolean,
      default: false
    },
    servicerName: {
      type: String,
      default: 'Servicer Profile Image',
      required: false
    },
    showAccountIcon: {
      type: Boolean,
      default: false,
      required: false
    },
    showIcon: {
      type: Boolean,
      default: false,
      required: false
    },
    small: {
      type: Boolean,
      default: false
    },
    squareSize: {
      type: String,
      default: '60',
      required: false
    },
    tile: {
      type: Boolean,
      default: true,
      required: false
    },
    useFullInitials: {
      type: Boolean,
      default: false
    },
    xLarge: {
      type: Boolean,
      default: false
    },
    initialStyle: {
      type: Object,
      required: false,
      default: () => ({})
    }
  }
}
</script>
