/*
* SNAC - SimpleNexus Analytics Capture (front end edition)
*
* These are methods for logging analytics events to Segment.
*   logToSegment(...) should be called when an event should be sent to segment
* The rest of the methods are for directly communicating with the SDK and shouldn't need to be called.
*
* See SNAC confluence docs for more information about SimpleNexus analytics tracking
*/

import { query } from '~/services/graphqlService.js'
import Sentry from '~/util/sentry'

let shouldLogToSegment = false

function track (eventName, userId, properties) {
  // userId param should always be present
  if (!userId) {
    Sentry().captureMessage('Analytics track() call made without a userId')
    return
  }
  // Only send events if analytics object has been loaded (i.e. user is signed in)
  /* eslint-disable no-undef */
  if (typeof analytics !== 'undefined') {
    identify(userId)
    analytics.track(
      eventName,
      properties
    )
  }
  /* eslint-enable no-undef */
}

function identify (userId, traits) {
  // Only send events if analytics object has been loaded (i.e. user is signed in)
  /* eslint-disable no-undef */
  if (typeof analytics !== 'undefined') {
    analytics.identify(
      userId,
      traits
    )
  }
  /* eslint-enable no-undef */
}
const Snac = {
  logToSegment (payload) {
    if (shouldLogToSegment) {
      track(payload.eventName, payload.currentUserGuid, payload.properties)
    }
  }
}

export default {
  install (Vue, options) {
    Vue.prototype.$snac = Snac
    const params = `
    query Settings {
      effective_servicer_profile_settings {
        feature_setting {
          log_analytics_to_segment
        }
      }
    }
    `
    query(params).then(response => {
      if (response.data.effective_servicer_profile_settings && response.data.effective_servicer_profile_settings.feature_setting.log_analytics_to_segment) {
        shouldLogToSegment = true
      }
    })
  }
}
