<template>
  <div>
    <!-- For medium and up screens, this "business card" appears in the middle of the screen. For smaller
    screens, look at the dialog below. -->
    <v-dialog
      v-if="$vuetify.breakpoint.mdAndUp"
      v-model="dialog"
      max-width="45%"
    >
      <v-card>
        <v-toolbar
          dark
          color="primary"
          style="height:40px;"
          flat
        >
          <v-spacer />
          <v-btn
            icon
            dark
            aria-label="close"
            class="mt-0 ml-2 mr-3"
            @click.native="dialog = false"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-layout
          :style="{'height:100px;': !showLargeNameText(fullName(servicer)), 'height:120px;': showLargeNameText(fullName(servicer))}"
          row
          class="contact--header primary px-3"
        >
          <v-flex
            xs9
            offset-xs4
          >
            <h3
              :class="{'display-1 mt-3': !showLargeNameText(fullName(servicer)),'display-3': showLargeNameText(fullName(servicer))}"
              class="light ml-4 white--text"
            >
              {{ fullName(servicer) }}
            </h3>
            <p class="ml-4 white--text">
              {{ servicer.title || personTitle }}
              <span v-if="servicer.license !== ''">
                {{ `| NMLS #${servicer.license}` }}
              </span>
            </p>
          </v-flex>
        </v-layout>
        <v-layout
          row
          class="pt-3 px-3"
        >
          <v-flex xs3>
            <div
              :class="{'largeTextAvatarImage': fullName(servicer).length < 12, 'smallTextAvatarImage': fullName(servicer).length >= 12}"
              class="avatarImage"
            >
              <default-avatar
                :image-url="effectiveServicerProfileUrl"
                :servicer-name="servicer.name"
                :square-size="avatarSquareSize"
                :avatar-color="'secondary'"
                :initial-size="avatarInitialSize"
              />
            </div>
          </v-flex>
          <v-flex
            :class="{'mb-4': !hasAction}"
            xs8
            offset-xs1
            style="word-wrap: break-word;"
          >
            <p class="subheading ml-4">
              <span v-if="servicer.unformatted_phone">
                <span>M: </span>
                <a
                  :href="'tel:+1' + servicer.unformatted_phone"
                  class="no-highlight"
                >
                  {{ servicer.unformatted_phone | formatPhone }}
                </a>
              </span>
              <br>
              <span v-if="servicer.office_unformatted_phone">
                <span>O: </span>
                <a
                  :href="'tel:+1' + servicer.office_unformatted_phone"
                  class="no-highlight"
                >
                  {{ servicer.office_unformatted_phone | formatPhone }}
                </a>
              </span>
              <br>
              <a
                :href="'mailto:' + servicer.email"
                class="no-highlight"
              >
                {{ servicer.email }}
              </a>
              <br>
              <a
                :href="servicer.website"
                class="no-highlight"
              >
                {{ servicer.website }}
              </a>
            </p>
            <p class="ml-4">
              {{ addressLineOne(servicer) }}
              <br>
              {{ addressLineTwo(servicer) }}
            </p>
            <template
              v-if="linkTypes.length !== 0"
            >
              <div class="mb-4">
                <span
                  v-for="link in servicerWebLinks"
                  id="servicerWebLinks"
                  :key="link.id"
                  class="ml-4"
                >
                  <a
                    :href="link.url"
                    target="_blank"
                  >
                    {{ linkText(link) }}
                  </a>
                  <br :key="'br-' + link.id">
                </span>
              </div>
              <template>
                <a
                  v-for="link in servicerSocialLinks"
                  :key="link.id"
                  :href="link.url"
                  :aria-label="link.url"
                  target="_blank"
                  class="ml-4"
                >
                  <v-icon>{{ $vuetify.icons[`sn-icon-${ linkIcon( link ) }`] }}</v-icon>
                </a>
              </template>
            </template>
          </v-flex>
        </v-layout>
        <v-layout
          v-if="hasAction"
          row
          align-start
          class="px-3 pb-4"
        >
          <v-btn
            class="ml-4 mb-3 btn--xlarge primary white--text sel_button_confirm_servicer"
            style="width:175px"
            color="primary"
            @click="confirmServicer"
          >
            {{ actionText }}
          </v-btn>
        </v-layout>
      </v-card>
    </v-dialog>
    <!-- For small and down screens, this full screen dialog appears instead of the "business card" popup -->
    <v-dialog
      v-else
      v-model="dialog"
      :overlay="false"
      fullscreen
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
          dark
          color="primary"
          style="height:60px;"
        >
          <v-btn
            icon
            dark
            aria-label="close"
            @click.native="dialog = false"
          >
            <v-icon>close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ servicer.name }} {{ servicer.last_name }}</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-layout row>
            <v-flex
              xs2
              sm1
            >
              <default-avatar
                :image-url="effectiveServicerProfileUrl"
                :servicer-name="servicer.name"
                :avatar-color="'secondary'"
              />
            </v-flex>
            <v-flex
              :class="{'ml-3': $vuetify.breakpoint.sm, 'ml-4': $vuetify.breakpoint.xs}"
              xs9
            >
              <h5 class="headline headline mb-1">
                {{ servicer.name }} {{ servicer.last_name }}
              </h5>
              <h2 class="subheading">
                {{ servicer.title || 'Loan Officer' }}
              </h2>
            </v-flex>
          </v-layout>
          <v-layout
            row
            class="mt-2"
          >
            <v-flex xs12>
              <p class="title mb-1">
                Contact
              </p>
              <p class="subheading mb-1">
                <a :href="'tel:+1' + servicer.unformatted_phone">{{ servicer.unformatted_phone | formatPhone }}</a>
              </p>
              <p class="subheading mb-1">
                <a :href="'mailto:' + servicer.email">{{ servicer.email }}</a>
              </p>
              <p>
                {{ addressLineOne(servicer) }}
                <br>
                {{ addressLineTwo(servicer) }}
              </p>
              <p class="title mb-1 mt-4">
                Resources
              </p>
              <p class="subheading mb-1">
                <a :href="servicer.website">
                  My website
                </a>
              </p>
              <p
                v-for="link in servicerWebLinks"
                :key="link.id"
                class="subheading mb-1"
              >
                <a
                  :href="link.url"
                  target="_blank"
                >
                  {{ linkText(link) }}
                </a>
              </p>
              <p class="subheading mb-1">
                <span v-if="servicer.license && servicer.license.indexOf('NMLS #') === -1 && servicer.license !== ''">NMLS #: </span>{{ servicer.license }}
              </p>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-actions>
          <v-layout
            v-if="hasAction"
            row
            align-start
          >
            <v-btn
              class="ml-2 mb-3 btn--xlarge primary white--text sel_button_confirm_servicer"
              style="width:175px"
              color="primary"
              @click="confirmServicer"
            >
              {{ actionText }}
            </v-btn>
          </v-layout>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import defaultAvatar from '../common/defaultAvatar.vue'
export default {
  name: 'BorrowerBusinessCard',
  components: {
    'default-avatar': defaultAvatar
  },
  filters: {
    formatPhone (phone) {
      if (phone) {
        return phone.substr(0, 3) + '-' + phone.substr(3, 3) + '-' + phone.substr(6, 4)
      }
    }
  },
  props: {
    hasAction: {
      type: Boolean,
      default: false,
      required: false
    },
    actionText: {
      type: String,
      default: '',
      required: false
    },
    servicer: {
      type: Object,
      default: () => {},
      required: true
    },
    servicerLinks: {
      type: Array,
      default: () => [],
      required: false
    },
    linkTypes: {
      type: Array,
      default: () => [],
      required: false
    },
    personTitle: {
      type: String,
      default: 'Loan Originator',
      required: false
    },
    value: {
      type: Boolean,
      default: false,
      required: true
    }
  },
  data () {
    return {
      fetchedWebLinks: [],
      dialog: false,
      validProfilePic: true
    }
  },
  computed: {
    avatarSquareSize () {
      if (this.$vuetify.breakpoint.xl) return '194'
      if (window.innerWidth >= 1264 && window.innerWidth < 1400) return '150'
      if (this.$vuetify.breakpoint.lg) return '194'
      if (this.$vuetify.breakpoint.md) return '130'
      return ''
    },
    avatarInitialSize () {
      if (this.$vuetify.breakpoint.xl) return '124'
      if (this.$vuetify.breakpoint.lg) return '88'
      if (this.$vuetify.breakpoint.md) return '68'
      return ''
    },
    effectiveServicerProfileUrl () {
      if (this.servicer.profile && this.servicer.profile.url) return this.servicer.profile.url
      if (this.servicer.profile_url) return this.servicer.profile_url
      return ''
    },
    servicerSocialLinks () {
      if (this.servicerLinks) return this.servicerLinks.filter(l => l.social === true)
      if (this.servicer.effective_web_links) return this.servicer.effective_web_links.filter(l => l.social === true)
      return ''
    },
    servicerWebLinks () {
      if (this.servicerLinks) return this.servicerLinks.filter(l => l.social === false)
      if (this.servicer.effective_web_links) return this.servicer.effective_web_links.filter(l => l.social === false)
      return ''
    }
  },
  watch: {
    value (val, oldVal) {
      this.dialog = this.value
    },
    dialog (val, oldVal) {
      this.$emit('input', this.dialog)
    }
  },
  created () {
    this.dialog = this.value
  },
  methods: {
    addressLineOne (servicer) {
      let firstLine = ''
      if (servicer.street1) firstLine += servicer.street1
      if (servicer.street2) firstLine += ', ' + servicer.street2
      return firstLine
    },
    addressLineTwo (servicer) {
      let secondLine = ''
      if (servicer.city) secondLine += servicer.city + ', '
      if (servicer.state) secondLine += servicer.state
      if (servicer.zip) secondLine += ' ' + servicer.zip
      return secondLine
    },
    showLargeNameText (name) {
      if (name.length >= 12) return false
      if (name.length < 12 && this.$vuetify.breakpoint.lgAndUp) return true
      return false
    },
    linkIcon (link) {
      var linkType = this.linkTypes.filter(l => { return l.id === link.link_type_id })[0]
      var icons = {
        facebook: 'facebook',
        google: 'google-plus',
        linkedin: 'linkedin',
        twitter: 'twitter',
        yelp: 'yelp',
        youtube: 'youtube',
        ie: 'link',
        rss: 'link',
        home_search: 'link',
        local_coupons: 'link',
        trulia: 'trulia',
        zillow: 'zillow',
        pinterest: 'pinterest',
        instagram: 'instagram',
        path2own: 'link',
        preflight: 'link',
        prequalified: 'link',
        licensing: 'link',
        'income-analysis': 'link',
        loan_status: 'link',
        home_scouting: 'link',
        film: 'link'
      }
      if (linkType !== undefined) {
        return icons[linkType.icon_name]
      } else {
        return 'link'
      }
    },
    linkText (link) {
      if (link.link_text !== null && link.link_text !== '') {
        return link.link_text
      } else {
        const linkType = this.linkTypes.filter(t => {
          return t.id === link.link_type_id
        })[0]
        if (linkType !== undefined) {
          return linkType.name
        } else {
          return 'Web link'
        }
      }
    },
    confirmServicer () {
      this.$emit('action', this.servicer.default_code)
    },
    fullName (servicer) {
      return servicer.name + ' ' + servicer.last_name
    }
  }
}
</script>
<style>
.smallTextAvatarImage {
  margin-left:24px;
  margin-top: -105px;
}
.largeTextAvatarImage {
  margin-left:24px;
  margin-top: -115px;
}
.contact--header {
  margin: 0;
}
</style>
