<template>
  <v-app>
    <sn-gradient-header
      :overlay-radius="24"
      :hide-back-btn="true"
      :class="['gradient-header', {'sm': isMobile}]"
    >
      <div style="height: 84px" />
    </sn-gradient-header>
    <div
      id="login"
      class="login-container"
      :style="cssVars"
    >
      <v-card
        :class="['login-card mt-10', {'sm': isMobile}]"
        :flat="isMobile"
      >
        <v-img
          v-if="!isMobile && !loading"
          :src="effectiveLogo"
          width="227"
          class="mb-5"
          data-cy="logo"
        />
        <v-avatar
          v-else-if="!loading"
          color="snWhite"
          size="80"
          class="avatar-logo"
        >
          <v-img
            :src="smallLogo"
            contain
            max-width="40px"
          />
        </v-avatar>
        <stateful-auth
          :auto-login="state === 'login'"
          :init="state"
          @title="value => { titleText = value }"
          @change-state="(value) => {state = value}"
        />
      </v-card>
    </div>
  </v-app>
</template>

<script>
import StatefulAuth from '~/components/common/v2/authorization/_statefulAuth.vue'
import logo from '~/assets/images/logos/ncino_mortgage_logo_dark.svg'
import logoNoText from '~/assets/images/logos/ncino_logo_no_text_color.png'
import brandThemeMixin from '~/components/common/brandThemeMixin'
import cookie from 'js-cookie'
import gql from 'graphql-tag'

export default {
  name: 'Login',
  components: {
    StatefulAuth
  },
  mixins: [brandThemeMixin],
  data () {
    return {
      state: 'login',
      ssoLogin: false,
      logo,
      logoNoText,
      effectiveLogoUrl: '',
      effectiveAppIconUrl: ''
    }
  },
  computed: {
    effectiveLogo () {
      return this.effectiveLogoUrl || this.logo
    },
    isMobile () {
      return this.$vuetify?.breakpoint?.mdAndDown
    },
    contentMaxWidth () {
      if (this.isMobile) {
        return '100%'
      } else {
        return '560px'
      }
    },
    cssVars () {
      return {
        '--content-max-width': this.contentMaxWidth
      }
    },
    loading () {
      return this.$apollo?.loading
    },
    smallLogo () {
      return this.effectiveAppIconUrl || this.logoNoText
    }
  },
  created () {
    if (cookie.get('staging') === 'true' && window.location.href.includes('tmp-') && window.location.href.includes('snstaging')) {
      this.$snackbard.show({
        color: 'warning',
        timeout: 30000,
        text: 'You have reached snstaging.com. If you are looking for a feature environment, you need to redeploy it!'
      })
    }
  },
  beforeCreate () {
    // used by brandThemeMixin to fetch custom themes
    if (this.$store) {
      this.$store.activationCode = this.$route.params.code
    }
  },
  apollo: {
    // runs when custom domain is present without a sac
    noSacData: {
      query: gql` query landingPageQueryNoSac($code: String) {
        noSacData: public {
          organization(servicer_activation_code: $code) {
            effective_logo_url
            effective_app_icon_url
          }
        }
      }`,
      skip () {
        return !!this.$route.params.code
      },
      result ({ data }) {
        this.effectiveLogoUrl = data.noSacData?.organization?.effective_logo_url
        this.effectiveAppIconUrl = data.noSacData?.organization?.effective_app_icon_url
      },
      error ({ error }) {
        this.$sentry.captureException(error)
      }
    },
    publicData: {
      query: gql` query landingPageQuery($code: String!) {
        publicData: public {
           servicer_profile(servicer_activation_code: $code) {
            effective_app_icon_url
            effective_company_logo_url
          }
        }
      }
      `,
      variables () {
        return {
          code: this.$route.params.code
        }
      },
      skip () {
        return (
          !this.$route.params.code
        )
      },
      result ({ data }) {
        if (data.publicData.servicer_profile) {
          this.effectiveLogoUrl = data.publicData.servicer_profile.effective_company_logo_url
          this.effectiveAppIconUrl = data.publicData.servicer_profile.effective_app_icon_url
        }
      },
      error ({ error }) {
        this.$sentry.captureException(error)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '~/assets/css/snui';

  .login-container {
    margin: 0 auto;
    padding: 0 16px;
    max-width: var(--content-max-width);
  }

  .login-card {
    position: relative;
    width: 100vw;
  }

  .login-card.sm {
    top: 10px;
    border: none;
    box-shadow: none;
    padding: 0;
  }

  .login-card:not(.sm) {
    top: -155px;
    padding: 56px;
    border-radius: 16px;
    box-shadow: $sn-shadow-depth-4
  }

  .avatar-logo {
    position: absolute;
    top: -105px;
    left: calc(50% - 40px);
    border-radius: 50% !important;
    box-shadow: 0px 1px 1.5px rgba(31, 31, 31, 0.04), 0px 2px 5px rgba(31, 31, 31, 0.1), 0px 3px 9px 6px rgba(31, 31, 31, 0.05)
  }

  ::v-deep .v-avatar .v-image {
    border-radius: 0px !important;
  }

  .gradient-header.sm {
    height: 80px !important;
  }

  .gradient-header:not(.sm) {
    height: 230px !important;
  }
</style>
