<template>
  <v-layout
    v-show="loading || playingAnimation"
    column
    align-center
    justify-center
    data-cy="loading-animation"
  >
    <v-flex shrink>
      <div
        id="loading-view"
        ref="loadingView"
      />
    </v-flex>

    <v-flex
      v-for="message in messages"
      :key="message"
      shrink
      class="align-center"
    >
      <span data-cy="loading-message">{{ message }}</span>
    </v-flex>
  </v-layout>
</template>
<script>
import loadingJSON from '~/assets/lottieAnimations/loading.json'
import lottie from 'lottie-web'

export default {
  name: 'SNLoader',

  props: {
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    waitForAnimation: {
      type: Boolean,
      require: false,
      default: true
    },
    messages: {
      type: Array,
      required: false,
      default: () => ['Just a moment...']
    },
    speed: {
      type: Number,
      required: false,
      default: 1
    }
  },

  data () {
    return {
      animation: null
    }
  },

  computed: {
    playingAnimation () {
      return this.animation && (this.animation.loop || this.animation.currentFrame !== this.animation.totalFrames - 1)
    }
  },

  watch: {
    loading (value) {
      if (value) {
        this.show()
      } else {
        this.hide(!this.waitForAnimation)
      }
    },
    playingAnimation (value) {
      if (!value) {
        this.$emit('stopped')
      }
    }
  },

  mounted () {
    if (this.loading) {
      this.show()
    }
  },

  methods: {
    mountAnimation () {
      if (this.animation) {
        return
      }
      this.animation = lottie.loadAnimation({
        container: this.$refs.loadingView,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: loadingJSON
      })
    },
    show () {
      this.mountAnimation()
      this.animation.loop = true
      this.animation.setSpeed(this.speed)
      this.animation.play()
    },
    hide (immediately = false) {
      if (immediately) {
        this.animation.stop()
        this.animation.loop = false
        this.animation.currentFrame = this.animation.totalFrames - 1
      } else {
        this.animation.loop = false
      }
    }
  }
}
</script>
<style scoped>
  #loading-view {
    height: 150px;
    width: 150px;
  }
  .align-center {
    text-align: center;
  }
</style>
