import gql from 'graphql-tag'
import 'unfetch/polyfill'
import apolloClient from '~/util/apolloClient'

const { client } = apolloClient([], [], [], { connectToDevTools: false })

function query (params, variables, fetchPolicy = null) {
  const queryParams = {
    query: gql`${params}`,
    variables: variables
  }
  if (fetchPolicy) {
    queryParams.fetchPolicy = fetchPolicy
  }
  return client.query(queryParams)
}

function mutate (params, variables) {
  return client.mutate({
    mutation: gql`${params}`,
    variables: variables
  })
}

export { query, mutate }
