import snThemeService from '../../services/snThemeService'
import { snThemeMixin } from '@simplenexus/snui'

export default {
  name: 'BrandThemeMixin',
  extends: snThemeMixin,

  created () {
    if (this.disableSnThemeMixin) {
      return
    }

    let code = null
    if (this.$store) {
      if (this.$store.activationCode) {
        code = this.$store.activationCode
      } else if (this.$store.servicerEmail) {
        code = this.$store.servicerEmail
      }
    }
    snThemeService.get(code)
      .then((response) => {
        this.sn_brandThemeMixin_setVuetifyColorsFromResponseData(response)
        this.sn_themeMixin_hasColors = true
      }).catch(e => {
        this.sn_themeMixin_hasColors = true
        this.$sentry.captureEvent({
          message: 'Error fetching custom ui theme',
          extra: {
            store: this.$store,
            sac: this.$store ? this.$store.activationCode : undefined,
            error: e.message
          }
        })
      })
  },

  methods: {
    sn_brandThemeMixin_setVuetifyColorsFromResponseData (response) {
      const primaryColor = this.sn_brandThemeResponseIsValid(response, 'primary') ? response.data.primary : '#11395B'
      const secondaryColor = this.sn_brandThemeResponseIsValid(response, 'secondary') ? response.data.secondary : '#C3CDD6'
      const accentColor = this.sn_brandThemeResponseIsValid(response, 'secondary') ? response.data.accent : primaryColor
      if (this.$vuetify?.theme?.themes !== undefined) { // vuetify 2 theme structure
        this.$vuetify.theme.themes.light.primary = primaryColor
        this.$vuetify.theme.themes.light.secondary = secondaryColor
        this.$vuetify.theme.themes.light.accent = accentColor
        // vuetify 2 apps also include the snui lib. So we can change brand colors
        // provide update brand colors to vuetify theme
        this.$vuetify.theme.themes.light.snBrand = primaryColor
        this.$vuetify.theme.themes.light.snBrand01 = this.sn_themeMixin_createColorShade(primaryColor, 0.05)
        this.$vuetify.theme.themes.light.snBrand02 = this.sn_themeMixin_createColorShade(primaryColor, 0.25)
        this.$vuetify.theme.themes.light.snBrand03 = primaryColor

        // some snui components use css variables to grab brand colors
        const r = document.querySelector(':root')
        r.style.setProperty('--sn-brand-01', this.$vuetify.theme.themes.light.snBrand01)
        r.style.setProperty('--sn-brand-02', this.$vuetify.theme.themes.light.snBrand02)
        r.style.setProperty('--sn-brand-03', this.$vuetify.theme.themes.light.snBrand03)
      } else { // vuetify 1 theme structure
        this.$vuetify.theme.primary = primaryColor
        this.$vuetify.theme.secondary = secondaryColor
        this.$vuetify.theme.accent = accentColor
      }
      this.customColorsRetrieved = true
    },
    sn_brandThemeResponseIsValid (response, type) {
      return response && response.data && (response.data[type] !== '') && this.sn_themeMixin_validHexCode(response.data[type])
    }
  }
}
