<template>
  <div>
    <v-card
      flat
    >
      <v-layout
        row
        wrap
        class="center-alignment"
      >
        <v-flex>
          <sn-text-field
            v-model="searchText"
            :placeholder="searchLabelText"
            class="customizeLabel mt-5 mr-3"
          />
        </v-flex>
        <v-flex
          v-if="downloading && searchText !== ''"
          xs2
          class="mt-6"
        >
          <v-progress-circular
            indeterminate
            color="secondary"
            class="ml-3"
          />
        </v-flex>
      </v-layout>
      <scrollable-list
        v-if="shouldShowList"
        :size="50"
        :remain="8"
        :bench="10"
        class="mt-3"
      >
        <v-list
          two-line
          subheader
        >
          <template
            v-for="servicer in searchResults"
          >
            <v-list-item
              :key="servicer.id"
              class="px-3 mt-3 hoverable"
              avatar
              @click="selectedServicer = servicer; confirmExpertDialog = true"
            >
              <v-list-item-avatar>
                <default-avatar
                  :image-url="servicer.profile_url"
                  :servicer-name="fullName(servicer)"
                />
              </v-list-item-avatar>
              <v-list-item-content
                class="pl-4"
              >
                <v-list-item-title>
                  {{ fullName(servicer) }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ location(servicer) }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
      </scrollable-list>
      <p
        v-if="showNoMatchesFound"
      >
        No Matches Found
      </p>
    </v-card>
    <borrower-business-card
      v-if="!isEmptyObject(selectedServicer)"
      v-model="confirmExpertDialog"
      :action-text="'Select'"
      :has-action="true"
      :servicer="selectedServicer"
      @action="confirmServicerAndContinue"
    />
    <v-layout
      row
      wrap
      class="text-xs-center"
    >
      <v-flex
        md12
        xs8
        class="mt-6"
      >
        <v-btn
          v-if="showContinueButton"
          class="mx-auto btn--xlarge primary text--white sel_button_get_skip"
          style="width:175px;"
          color="primary"
          outline
          @click="$emit('choice-made', 'skip_selection')"
        >
          Continue
        </v-btn>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
import get from 'lodash/get'

import snCompaniesService from '../../../services/snCompaniesService.js'
import borrowerBusinessCard from '../../borrowerApp/borrowerBusinessCard.vue'
import defaultAvatar from './defaultAvatar.vue'
import scrollableList from 'vue-virtual-scroll-list'

export default {
  name: 'ChooseLo',
  components: {
    'scrollable-list': scrollableList,
    'borrower-business-card': borrowerBusinessCard,
    'default-avatar': defaultAvatar
  },
  props: {
    activationCode: {
      type: String,
      default: '',
      required: true
    }
  },
  data () {
    return {
      confirmExpertDialog: false,
      customTitle: 'Are you working with someone on your loan?',
      downloading: false,
      searchResults: [],
      searchLabelText: 'Search by name, city, or location',
      searchText: '',
      servicerProfiles: [],
      selectedServicer: {}
    }
  },
  computed: {
    shouldShowList () {
      if (this.searchText === '') return false
      if (this.searchText !== '' && this.searchResults.length === 0) return false
      return true
    },
    showNoMatchesFound () {
      if (this.searchText !== '' && this.searchResults.length === 0 && !this.downloading) return true
      return false
    },
    showContinueButton () {
      return this.$store?.servicer?.allowContinueSkipWhenChoosingLo
    }
  },
  watch: {
    searchText: {
      handler: function (oldVal, newVal) {
        const debouncedSearch = debounce(this.queryServicers, 500)
        debouncedSearch()
      }
    }
  },
  created () {
    if (get(this, '$store.borrowerWebContent.chooseLoHeaderText', false)) this.customTitle = this.$store.borrowerWebContent.chooseLoHeaderText
    // Currently UX wants the web title for this step to be different from the title
    // used by web/returned from the stateDefinition. This event customizes the title.
    this.$emit('customize-title', this.customTitle)
  },
  methods: {
    confirmServicerAndContinue (newSac) {
      this.$emit('choice-made', 'save_servicer', newSac)
    },
    fullName (servicer) {
      return servicer.name + ' ' + servicer.last_name
    },
    isEmptyObject (givenObject) {
      if (Object.keys(givenObject).length === 0) return true
      else return false
    },
    location (servicer) {
      if (servicer.city) {
        return servicer.city + ', ' + servicer.state
      } else if (servicer.state) {
        return servicer.state
      } else { return '' }
    },
    queryServicers () {
      this.downloading = true
      if (this.searchText === '') {
        this.searchResults = []
      } else {
        snCompaniesService.searchCompanyServicers(this.activationCode, this.searchText).then(response => {
          this.downloading = false
          const servicerProfiles = get(response, 'data.public.organization.servicer_profiles', [])
          if (servicerProfiles) {
            this.searchResults = servicerProfiles
          }
        })
      }
    }
  }
}
</script>
<style scoped>
.customizeLabel label {
  text-transform: none !important;
}
.progressCircle {
  text-align: center;
}
.center-alignment{
  text-align: center;
  margin: 0 !important;
  width: 100%;
}
</style>
