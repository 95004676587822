import axios from '~/util/axios'

// sometimes we get a 502 from the server but it's not for any major reason.
// To try to prevent that from causing theme loading issues, we added some retry logic
import axiosRetry from 'axios-retry'
axiosRetry(axios, {
  retries: 3,
  retryDelay: (retryCount) => retryCount * 500,
  retryCondition: axiosRetry.isRetryableError
})

export default {
  get (sac) {
    return axios.post('/api/v1/custom_ui_theme', {
      sac: sac
    })
  }
}
