import axios from '~/util/axios'
import { query } from './graphqlService.js'

export default {
  getReport (id, reportType, startDate = null, endDate = null) {
    if (startDate === null || endDate === null) {
      const today = new Date()

      startDate = today.toISOString().slice(0, 10)
      endDate = startDate
    }

    return axios.get(`/companies/${id}/reports/${reportType}`, {
      params: {
        start_date: startDate,
        end_date: endDate
      }
    })
  },

  postUserImport (companyId, isAnUpdate, sendWelcomeEmails, initiallyActive, csvFile) {
    return fetch(`/companies/${companyId}/import_users`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': (document.querySelector('[name=csrf-token]') !== null ? document.querySelector('[name=csrf-token]').content : '')
      },
      body: JSON.stringify({
        is_an_update: isAnUpdate,
        send_welcome_emails: sendWelcomeEmails,
        initially_active: initiallyActive,
        import_file: csvFile
      })
    })
  },

  postTeamUserImport (companyId, isAnUpdate, sendWelcomeEmails, initiallyActive, csvFile) {
    return axios.post(`/companies/${companyId}/import_team`, {
      is_an_update: isAnUpdate,
      send_welcome_emails: sendWelcomeEmails,
      initially_active: initiallyActive,
      import_file: csvFile
    })
  },

  getUserImportExampleCSV (companyId) {
    return axios.get(`/companies/${companyId}/download_example_user_csv`)
  },

  getTeamImportExampleCSV (companyId) {
    return axios.get(`/companies/${companyId}/download_example_team_csv`)
  },

  getAllCompanyUsers (companyId, forDownload) {
    return axios.get(`/companies/${companyId}/users_regions_branches_json`)
  },

  getCompanyDefaultCodeWithActivationCode (activationCode) {
    const params = `
    query getDefaultCode ($servicer_activation_code: String!) {
      public {
        company(servicer_activation_code: $servicer_activation_code) {
          allow_borrowers_to_choose_lo_in_borrower_signup
          default_servicer_activation_code
        }
      }
    }
    `
    const variables = { servicer_activation_code: activationCode }
    return query(params, variables)
  },

  searchCompanyServicers (activationCode, searchText) {
    const params = `
    query searchCompanyServicers($code: String, $searchText: String!) {
      public {
        organization(servicer_activation_code: $code) {
          servicer_profiles(search: $searchText) {
            id
            default_sac_id
            active
            name
            website
            email
            license
            profile_url
            last_name
            title
            city
            state
            street1
            street2
            unformatted_phone
            default_code
          }
        }
      }
    }
    `
    const variables = {
      code: activationCode,
      searchText: searchText
    }
    return query(params, variables)
  },

  postNewAdmin (companyId, subregionType, subregionId, userId) {
    return axios.post(`/companies/${companyId}/make_admin`, {
      user_id: userId,
      subregion_id: subregionId,
      subregion_type: subregionType
    })
  },

  removeAdmin (companyId, userId) {
    return axios.post(`/companies/${companyId}/remove_admin`, {
      user_id: userId
    })
  }
}
